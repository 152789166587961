<template>
  <b-container class="overflow-auto" fluid>
    <!-- Publisher Interface controls -->
    <h5 class="my-3"><strong>PUBLISHER FEEDS</strong></h5>
    <a-row class="my-2" :gutter="[8]">
      <a-col :xl="3" :md="6" :xs="12">
        <a-checkbox v-model="showActive">
          Show only active
        </a-checkbox>
      </a-col>
      <a-col :xl="4" :md="12" :xs="24">
        <a-select
          allowClear
          placeholder="Publisher"
          style="width: 100%"
          :showSearch="true"
          :filterOption="true"
          optionFilterProp="children"
          v-model="selectedPublisher"
          @change="getPublishers"
        >
          <a-select-option v-for="obj in XMLpublisher.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <b-row>
      <b-col class="my-1" md="6">
        <b-form-group class="mb-0" label="Per page:" label-cols-sm="2">
          <perPageSelect v-model="perPage" :storageKey="'xml-feeds'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'xml-feeds-columns'" :options="fields"></columnSelect>
          <CreateButton module="xml" target="feed"/>
        </b-form-group>
      </b-col>

      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      :bordered="true"
      :current-page="currentPage"
      :fields="filteredFields"
      :filter="filter"
      :hover="true"
      :items="showPubFeeds"
      :per-page="perPage"
      :small="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :striped="true"
      :tbody-tr-class="rowClass"
      show-empty
      stacked="md"
      @filtered="onFiltered"
    >
      <template #cell(Id)="data">
        <div class="items-center">{{ data.item.Id }}</div>
      </template>
      <template #cell(Name)="data">
        <div class="items-center">{{ data.item.Name }}</div>
      </template>
      <template #cell(PublisherName)="data">
        <div class="items-center text-break">{{ data.item.PublisherName }}</div>
      </template>
      <template #cell(Ads)="data">
        <div class="items-center">{{ data.item.Ads }}</div>
      </template>
      <template #cell(Description)="data">
        <div class="items-center text-break">{{ data.item.Description }}</div>
      </template>
      <template #cell(StatusString)="data">
        <a-checkbox :checked="(data.item.Status == 0)" @change="togglePubFeedStatus(data.item)">
        </a-checkbox>
      </template>
      <template #cell(SubidListMode)="data">
        {{ data.item.SubidListMode ? 'Whitelist' : 'Blacklist' }}
      </template>
      <template #cell(actions)="data">
        <div class="flex">
          <b-button
            @click="showModal(data.item)"
            class="btn-info mr-1"
            size="sm"
          >
            <i class="fa fa-info"></i>
          </b-button>
          <router-link class="add-button" @click.stop :to="{ name: 'xml-feed-update', params: { id: data.item.Id }  }">
            <b-button
              class="btn-info mr-1"
              size="sm"
              @click="editPubFeed(data.item.Id)"
            >
              <i class="fa fa-edit"></i>
            </b-button>
          </router-link>
          <b-button
            class="btn-info mr-1"
            size="sm"
            @click="copyFeed(data.item.Id, data.item)"
          >
            <i class="fa fa-copy"></i>
          </b-button>
        </div>
      </template>
      <template #cell(ImpressionTrackingMethod)="data">
        {{ impressionTrackingMethod[data.item.ImpressionTrackingMethod] }}
      </template>
      <template #cell(KeywordBlacklistsType)="data">
        {{ keywordListsType[data.item.KeywordBlacklistsType].Name }}
      </template>
      <!-- <template #cell(ClicksDailyLimit)="">
        1000
      </template> -->
    </b-table>
    <b-modal id="bv-modal-example" hide-footer size="lg" body-class="rtb-modal" class="rtb-modal">
      <template #modal-title>
        Feed Info
      </template>
      <div class="d-block" style="min-height: 500px">
        <div>
          <a-tabs default-active-key="1" class="mb-5">
            <a-tab-pane key="1" tab="URL Template">
              <div class="card">
                <div class="card-header p-3" style="background-color: rgba(237, 237, 243, 0.38)">
                  Search URL
                </div>
                <div class="card-body p-3">
                  <p>{{ modal.xmlSearchUrl }}</p>
                </div>
              </div>
              <div>
                <p class="parameters-title">Parameters</p>
                <div class="parameter">
                  <p class="parameter-key">feed</p>
                  <p class="parameter-value">Publisher feed ID.</p>
                </div>
              </div>
              <div class="parameter">
                <p class="parameter-key">auth</p>
                <p class="parameter-value">Publisher feed authorization code.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">subid</p>
                <p class="parameter-value">Sub ID with up to 16 characters. It must contain only alphanumeric and special characters (._-).</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">query</p>
                <p class="parameter-value">Search query used to get ad results. Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">user_ip</p>
                <p class="parameter-value">IP address of the visitor.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">ua</p>
                <p class="parameter-value">User-Agent of the visitor. Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">url</p>
                <p class="parameter-value">URL of the web page where ads will be displayed. Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">empty</p>
                <p class="parameter-value">HTTP status code to return if feed response is empty. Allowed values: 200 or 204. Default value: 200.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">count</p>
                <p class="parameter-value">Maximum number of results in response. Default value: 3.</p>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Publisher Reports">
              <div class="card mb-2">
                <div class="card-header p-3" style="background-color: rgba(237, 237, 243, 0.38)">
                  By Date
                  <!-- <button class="btn btn-info btn-secondary btn-sm"><i class="fe fe-copy"></i></button> -->
                </div>
                <div class="card-body p-3">
                  <p><a :href="modal.reportsByDate">{{ modal.reportsByDate }}</a></p>
                </div>
              </div>
              <div class="card mb-2">
                <div class="card-header p-3" style="background-color: rgba(237, 237, 243, 0.38)">
                  By Sub ID
                </div>
                <div class="card-body p-3">
                  <p><a :href="modal.reportsBySubid">{{ modal.reportsBySubid }}</a></p>
                </div>
              </div>
              <div class="card mb-2">
                <div class="card-header p-3" style="background-color: rgba(237, 237, 243, 0.38)">
                  By Country
                </div>
                <div class="card-body p-3">
                  <p><a :href="modal.reportsByCountry">{{ modal.reportsByCountry }}</a></p>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </b-modal>
    <b-row>
      <b-col class="my-1" md="6">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
            (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) + perPage).toLocaleString()
          }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'
import { Modal } from 'ant-design-vue'
import { cloneFeed } from '@/api/xml/pubfeed'

export default {
  components: {
    columnSelect,
    CreateButton: () => import('@/components/custom/create-button/index.vue'),
    perPageSelect,
  },
  data() {
    return {
      keywordListsType: [
        {
          Id: 0,
          Name: 'Exact',
        },
        {
          Id: 1,
          Name: 'Phrase',
        },
        {
          Id: 2,
          Name: 'Broad',
        },
      ],
      selectedPublisher: [],
      modal: {
        xmlSearchUrl: '',
        reportsByDate: '',
        reportsBySubid: '',
        reportsByCountry: '',
      },
      fields: [
        {
          key: 'actions',
          label: 'Actions',
          show: true,
          headerTitle: 'Actions',
          class: 'text-center',
          thStyle: 'width:120px',
        },
        {
          key: 'Id',
          label: 'Feed ID',
          show: true,
          headerTitle: 'Feed ID',
          sortable: true,
          thStyle: 'width:80px',
          tdClass: 'text-right',
        },
        {
          key: 'Name',
          label: 'Feed Name',
          show: true,
          headerTitle: 'Publisher Feed Name',
          tdClass: 'text-md-left text-center text-wrap text-break',
          sortable: true,
        },
        {
          key: 'PublisherName',
          label: 'Publisher',
          show: true,
          headerTitle: 'Publisher Name',
          sortable: true,
          thStyle: 'width:180px',
        },
        {
          key: 'Ads',
          label: 'Ads',
          show: true,
          headerTitle: 'Ads',
          sortable: false,
        },
        {
          key: 'Description',
          label: 'Description',
          show: true,
          headerTitle: 'Description',
          tdClass: 'text-break',
          sortable: true,
        },
        {
          key: 'FallbackUrl',
          label: 'Fallback',
          show: false,
          headerTitle: 'Fallback Url',
          sortable: true,
        },
        {
          key: 'ImpressionTrackingMethod',
          label: 'ITM',
          show: false,
          headerTitle: 'Impression Tracking Method',
          sortable: true,
          thStyle: 'width:150px',
        },
        {
          key: 'MinCPC',
          label: 'Min. CPC',
          show: true,
          headerTitle: 'Min. CPC',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-md-right text-center',
          formatter: '$formatCurrency',
          thStyle: 'width:85px',
        },
        {
          key: 'MaxCPC',
          label: 'Max. CPC',
          show: true,
          headerTitle: 'Max. CPC',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-md-right text-center',
          formatter: '$formatCurrency',
          thStyle: 'width:90px',
        },
        {
          key: 'SubidListMode',
          label: 'Subid Mode',
          show: false,
          headerTitle: 'Subid List Mode',
          sortable: true,
          thStyle: 'width:100px',
        },
        {
          key: 'RequestsDailyLimit',
          label: 'Requests Daily Limit',
          headerTitle: 'Requests Daily Limit',
          show: true,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:160px',
          summed: false,
        },
        {
          key: 'ClicksDailyLimit',
          label: 'Clicks Daily Limit',
          show: true,
          headerTitle: 'Clicks Daily Limit',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:140px',
          summed: false,
        },
        {
          key: 'KeywordBlacklistsType',
          label: 'KBT',
          show: false,
          headerTitle: 'Keyword Blacklists Type',
          sortable: true,
          thStyle: 'width:70px',
        },
        {
          key: 'StatusString',
          label: 'Active',
          show: true,
          headerTitle: 'Status',
          class: 'text-center',
          thStyle: 'width:80px',
        },
      ],
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      impressionTrackingMethod: ['Impression Pixel', 'Any Available Image'],
    }
  },
  computed: {
    ...mapState(['pubfeed', 'XMLpublisher', 'settings']),
    ...mapGetters('pubfeed', ['getPubFeeds', 'getActivePubFeeds']),
    showActive: {
      get: function () {
        return this.settings.showActivePubFeeds
      },
      set: function (value) {
        this.$store.commit('CHANGE_SETTING', {
          setting: 'showActivePubFeeds',
          value,
        })
      },
    },
    showPubFeeds() {
      return this.showActive ? this.getActivePubFeeds : this.getPubFeeds
    },
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.showPubFeeds.length
    },
  },
  methods: {
    getPublishers() {
      this.$store.dispatch('pubfeed/LOAD_PUBFEEDS_FOR_PUBLISHER', { PublisherId: this.selectedPublisher })
    },
    getFeed(id) {
      cloneFeed(id).then(response => {
        if (response) {
          // this.$router.push('/xml/feed/update/' + response.Pubfeed.Id)
          this.$router.push({
            name: 'xml-feed-update',
            params: { id: response.Pubfeed.Id },
          }).catch(() => {
          })
          this.$notification.success({
            message: 'Feed cloned',
            description: `Feed: ${response.Pubfeed.Name} has been successfully cloned.`,
          })
        }
      })
    },
    copyFeed(id, feed) {
      Modal.confirm({
        title: 'Are you sure you want to clone feed?',
        content: h => <div>{status} Clone feed: <strong>{feed.Name}</strong>?</div>,
        onOk: function () {
          this.getFeed(id)
        }.bind(this),
      })
    },
    showModal(item) {
      this.$bvModal.show('bv-modal-example')
      console.log(item)
      this.modal.xmlSearchUrl = item.XmlSearchUrl
      this.modal.reportsByDate = item.StatsByDate
      this.modal.reportsBySubid = item.StatsBySubid
      this.modal.reportsByCountry = item.StatsByCountry
    },
    rowClass(item, type) {
      if (item != null && item.Status === 1) return 'text-muted'
    },
    editPubFeed(id) {
      // this.$router.push('/xml/feed/update/' + id)
      this.$router.push({
        name: 'xml-feed-update',
        params: { id: id },
      }).catch(() => {
      })
    },
    togglePubFeedStatus(feed) {
      const status = feed.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change feed status?',
        content: h => <div>{status} feed <strong>{feed.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = feed.Status === 0 ? 1 : 0
          this.$store.dispatch('pubfeed/CHANGE_STATUS', {
            id: feed.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  created() {
    this.$store.dispatch('pubfeed/LOAD_PUBFEEDS')
    this.$store.dispatch('XMLpublisher/LOAD_PUBLISHERS')
  },
}
</script>
<style scoped>
.header-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.copy-button {
  outline: none;
  border-radius: 16px;
  background-color: #42baf9;
  color: #ffffff;
  padding: .2rem;
  cursor: pointer;
}
.rtb-modal {
  color: red;
}
.parameter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.parameter-key {
  width: 20%;
  font-weight: 600;
}
.parameter-value {
  width: 80%;
  text-align: left;
}
.parameters-title {
  font-size: 140%;
  font-style: bold;
}
@media only screen and (max-width: 992px) {
  .parameter-value {
    width: 75%;
    text-align: left;
  }
}
@media only screen and (max-width: 768px) {
  .items-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
